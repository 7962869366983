var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":12,"xl":6}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"lg":24,"xl":24}},[_c('a-card',{staticClass:"card card-body border-0"},[_c('div',{staticClass:"mb-4 d-flex justify-content-between align-items-center"},[_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"Recherche ici"},on:{"change":_vm.onSearch},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('a-form-item',{attrs:{"colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'ville',
                {
                  initialValue: _vm.ville,
                  rules: [
                    {
                      required: true,
                      message: 'ville est vide!',
                    } ],
                } ]),expression:"[\n                'ville',\n                {\n                  initialValue: ville,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'ville est vide!',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"300px"},on:{"change":_vm.listeCollecteur}},_vm._l((_vm.villes),function(ville){return _c('a-select-option',{key:ville.id,attrs:{"value":ville.id}},[_vm._v(" "+_vm._s(ville.libelle)+" ")])}),1)],1)],1),_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false},scopedSlots:_vm._u([{key:"etat",fn:function(text, record){return [(record.etat == 0)?_c('span',{staticClass:"text-success"},[_vm._v("Online")]):_vm._e(),(record.etat == 1)?_c('span',{staticClass:"text-danger"},[_vm._v("Offline")]):_vm._e()]}},{key:"operation",fn:function(text, record){return [_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                    name: 'Collecteur_detail',
                    params: { id: record.key },
                  }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)],1),_c('a-col',{attrs:{"span":12}},[(record.status == 1)?_c('a-popconfirm',{attrs:{"title":"Sûre de bloquer?"},on:{"confirm":function () { return _vm.block(record.key); }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"danger","size":"small"}},[_vm._v("Bloquer")])],1):_vm._e(),(record.status == 0)?_c('a-popconfirm',{attrs:{"title":"Sûre de débloquer?"},on:{"confirm":function () { return _vm.block(record.key); }}},[_c('a-button',{staticClass:"mx-2",attrs:{"type":"success","size":"small"}},[_vm._v("Debloquer")])],1):_vm._e()],1)],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-4"},[_c('div',[_c('p',[_vm._v("Page "+_vm._s(_vm.page)+"/"+_vm._s(_vm.total_page))])]),_c('div',[(_vm.page > 1)?_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.preview()}}},[_vm._v(" Retour ")]):_vm._e(),(_vm.page != _vm.total_page)?_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.next()}}},[_vm._v(" Suivant ")]):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }